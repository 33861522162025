    import { useState, useEffect } from 'react';

    const useUserRole = () => {
    const [userRole, setUserRole] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const loginType = JSON.parse(localStorage.getItem('currentUser'));
        if (loginType && loginType.types && loginType.types.length > 0) {
        setUserRole(loginType.types[0]);
        setCurrentUser(loginType.current_user);
        }
    }, []);

    return { userRole, currentUser };
    };

    export default useUserRole;
