import React from 'react';
import { Menu } from 'antd';
import {
  DashboardOutlined,
  UserAddOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Link, useNavigate} from 'react-router-dom';
import { removeItemFrmLocalStorage } from '../../utils/genericUtilities';

const { SubMenu } = Menu;

const SideMenuExecutive = ({ userRole }) => {
    const navigate = useNavigate();


    // const handleMenuClick = (menuName) => {
    //   setCurrentMenu(menuName);
    // };
  
    const handleLogout = () => {
        // Add your logout logic here
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        navigate('/');
      };
      const menuConfig = {
        'Dashboard': {
          visibleTo: [0,1,2,3,4],
          icon: <DashboardOutlined />,
          link: '/dashboard',
        },
        'Part Timers': {
          visibleTo: [0,1,2],
          icon: <UserAddOutlined />,
          subMenu: {
            'Create': {
              visibleTo: [0,1,2],
              link: '/employee-create',
            },   
            'List': {
              visibleTo: [0,1,2,3,4],
              link: '/employee-list',
            },           
          },
        },
        'Employer': {
          visibleTo: [0,1,2],
          icon: <UserAddOutlined />,
          subMenu: {
            'Create': {
              visibleTo: [0,1,2],
              link: '/employer-create',
            },   
            'List': {
              visibleTo: [0,1,2],
              link: '/employer-list',
            },           
          },
        },
        'Jobs': {
          visibleTo: [0,1,2],
          icon: <UserAddOutlined />,
          subMenu: {
            'Create': {
              visibleTo: [0,1,2],
              link: '/job-post-create',
            },   
            'List': {
              visibleTo: [0,1,2],
              link: '/job-post-list-all',
            },           
          },
        },

        'Complaints': {
          visibleTo: [0,1,2],
          icon: <UserAddOutlined />,
          subMenu: {
            'From Employer': {
              visibleTo: [0,1,2],
              link: '/employer-complaint-list',
            },   
            'From Employee': {
              visibleTo: [0,1,2],
              link: '/employee-complaint-list',

            },           
          },
        },
        'Payment': {
          visibleTo: [0,1,2],
          icon: <UserAddOutlined />,
          subMenu: {
            'Eligible For Payout': {
              visibleTo: [0,1,2],
              link: '/job-post-list',
            },   
                   
          },
        },
      
        'Executives': {
          visibleTo: [3],
          icon: <UserAddOutlined />,
          subMenu: {
            'Executive list': {
              visibleTo: [3],
              link: '/sales-executive-list',
            },           
          },
        },

        'Managers': {
          visibleTo: [0],
          icon: <UserAddOutlined />,
          subMenu: {
            'Manager List': {
              visibleTo: [0],
              link: '/manager-list',
            },           
          },
        },
        'Contact Profile': {
          visibleTo: [3,4],
          icon: <UserAddOutlined />,
          subMenu: {
            'Contact Profile list': {
              visibleTo: [0,1,2,3,4],
              link: '/contact-profile-list',
            },           
          },
        },
        'Funnel Sheet': {
          visibleTo: [3,4],
          icon: <UserAddOutlined />,
          subMenu: {
            'Funnel sheet list': {
              visibleTo: [0,1,2,3,4],
              link: '/funnel-sheet-list',
            },           
          },
        },
        // 'Dashboard2': {
        //   visibleTo: [0,1,2,3,4],
        //   icon: <DashboardOutlined />,
        //   link: '/dashboard',
        // },
        // 'Direct Menu3 ': {
        //   visibleTo: [0,1,2,3,4],
        //   icon: <DashboardOutlined />,
        //   link: '/dashboard',
        // },
        // 'View Users': {
        //   visibleTo: [0,1,2,3,4],
        //   icon: <TeamOutlined />,
        //   subMenu: {
        //     'View Admin List': {
        //       visibleTo: [0,1,2,3,4],
        //       link: '/view-admin-list',
        //     },
        //     'View Manager': {
        //       visibleTo: [0,1,2,3,4],
        //       link: '/view-manager',
        //     },
        //     'View Part Timers': {
        //       visibleTo: [0,1,2,3,4],
        //       link: '/view-part-timers',
        //     },
        //     'View Customer Support': {
        //       visibleTo: [0,1,2,3,4],
        //       link: '/view-customer-support',
        //     },
        //   },
        // },
        'Signout': {
          visibleTo: [0, 1, 2, 3, 4],
          icon: <DashboardOutlined />,
          link: '/',
          onClick: handleLogout, 
        },
      };

      const renderSubMenu = (submenu) => {
        return (
          <SubMenu key={submenu} icon={menuConfig[submenu].icon} title={submenu}>
            {Object.keys(menuConfig[submenu].subMenu).map((item) =>
              menuConfig[submenu].subMenu[item].visibleTo.includes(userRole) ? (
                <Menu.Item key={item}>
                  <Link to={menuConfig[submenu].subMenu[item].link}>{item}</Link>
                </Menu.Item>
              ) : null
            )}
          </SubMenu>
        );
      };
    
      return (
        <Menu theme="dark" mode="inline">
          {Object.keys(menuConfig).map((item) =>
            menuConfig[item].visibleTo.includes(userRole) ? (
              menuConfig[item].subMenu ? (
                renderSubMenu(item)
              ) : (
                <Menu.Item key={item} icon={menuConfig[item].icon} onClick={menuConfig[item].onClick}>
                  <Link to={menuConfig[item].link}>{item}</Link>
                </Menu.Item>
              )
            ) : null
          )}
        </Menu>
      );
    };
    
    export default SideMenuExecutive;