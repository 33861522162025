import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UserCount } from '../../sevices/account';

const useStyles = makeStyles({
    root: {
        minWidth: 250,
        maxWidth: 370,
        minHeight: 160,
        maxHeight: 280,
        margin: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: "rgb(241, 244, 255)", // Same background color as the other component
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    numericValue: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    smallText: {
        fontSize: 14,
    },
    // cardContainer: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     gap: '20px',
    //     justifyContent: 'center',
    // },
});

const SalesTargetCard = ({ target, numericValue1, smallText1, numericValue2, smallText2, backgroundColor }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} md={2} lg={2}>
            <Card variant="outlined" className={classes.root} style={{ backgroundColor }}>
                <CardContent>
                    <Typography className={classes.title} gutterBottom>
                        {target}
                    </Typography>
                    <Typography variant="h5" component="p" className={classes.numericValue}>
                        {numericValue1}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.smallText}>
                        {smallText1}
                    </Typography>
                    <Typography variant="h5" component="p" className={classes.numericValue}>
                        {numericValue2}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.smallText}>
                        {smallText2}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}

const DshboardUsersData = ({ currentMenu }) => {
    const classes = useStyles();
    const [userCount, setUserCount] = useState("");
    const [loading, setLoading] = useState(false);

    const userCountData = async () => {
        setLoading(true);
        try {
            const response = await UserCount({});
            if (response?.status === 200) {
                setUserCount(response?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log("Error:", error);
        }
    };

    useEffect(() => {
        userCountData();
    }, []);

    return (
        <Box>
            <Grid container spacing={2} className={classes.cardContainer}>
                <SalesTargetCard
                    target={<span style={{ fontSize: '20px' }}>Total </span>}
                    numericValue1={userCount?.total_employee}
                    smallText1="Part Timers "
                    backgroundColor="rgb(241, 244, 255)"
                />
                <SalesTargetCard
                    target={<span style={{ fontSize: '20px' }}>Active </span>}
                    numericValue1={userCount?.active_employee}
                    smallText1="Part Timers "
                    backgroundColor="rgb(252, 222, 234)"
                />
                <SalesTargetCard
                    target={<span style={{ fontSize: '20px' }}>Total </span>}
                    numericValue1={userCount?.total_employer}
                    smallText1="Employers"
                    backgroundColor="rgb(253, 243, 229)"
                />
                <SalesTargetCard
                    target={<span style={{ fontSize: '20px' }}>Active </span>}
                    numericValue1={userCount?.active_employer}
                    smallText1="Employers"
                    backgroundColor="rgb(255, 229, 246)"
                />
            </Grid>
        </Box>
    );
}

export default DshboardUsersData;
