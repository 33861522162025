import React from "react";
import { Link } from 'react-router-dom'
import { Menu, Dropdown } from 'antd';
import { DownOutlined, MenuOutlined, } from '@ant-design/icons';
// import proimg from '../../assets/img/300-1.jpg'
import "./header.css";


const menu = (
	<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded py-3">
		<div className="menu-item px-3">
			<div className="menu-content d-flex align-items-center px-3 mr-4">
				<div className="symbol symbol-50px me-5">
					<img alt="user" />
				</div>
				<div className="d-flex flex-column">
					<div className="fw-bolder d-flex align-items-center fs-16">Max Smith</div>
					<a href="JavaScript:;" className="fw-bold text-muted text-hover-primary fs-12">max@kt.com</a>
				</div>
			</div>
		</div>
		<div className="separator my-2"></div>
		<div className="menu-item px-3">
			<a href="JavaScript:;" className="menu-link">My Profile</a>
		</div>
		<div className="menu-item px-3">
			<a href="JavaScript:;" className="menu-link">
				<span className="menu-text">My Projects</span>
			</a>
		</div>
		<div className="separator my-2"></div>
		<div className="menu-item px-3">
			<a href="JavaScript:;" className="menu-link">Sign Out</a>
		</div>
	</div>
);
const PageHeader = () => {
	const menuToggle = () => {
		document.body.classList.toggle('menu-close');
	}
	return (
		<div className="header align-items-stretch page-header-container">
			<div className="container-fluid d-flex align-items-stretch justify-content-between">
				<div className="d-flex align-items-stretch">
					<div className="header-menu align-items-stretch">
						<div className="menu-item Header-menu-Icon">
							<span className="SideMenuIcon" onClick={menuToggle}>
								<MenuOutlined />
							</span>
						</div>
					</div>
				</div>
	
			</div>
		</div>
	);
};

export default PageHeader;