import React, { useEffect, useRef } from 'react';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const MapComponent = ({ locationData }) => {
  const googleMapRef = useRef(null);

  useEffect(() => {
    let map;

    async function initMap() {
        const locationDataModified = locationData?.map((val) => {
            return { lat: val?.latitude, lng: val?.longitude }
        });

        const position = locationDataModified[0];


        const { Map } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker");

        const infoWindow = new window.google.maps.InfoWindow({
            content: "",
            disableAutoPan: true,
        });
        const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        // The map, centered at Uluru
        map = new Map(googleMapRef.current, {
            zoom: 4,
            center: position,
            mapId: "AIzaSyDwMbwmoXn8e8JCFFTfzAyLsZOWxXq_n1c",
        });

        const markers = locationDataModified.map((val, index) => {
            // The marker, positioned at Uluru

            const label = labels[index % labels.length];

            const pinGlyph = new PinElement({
                glyph: label,
                glyphColor: "white",
            });

            const marker = new AdvancedMarkerElement({
                map: map,
                position: val,
                title: "Uluru",
                content: pinGlyph.element,
            });

            marker.addListener("click", () => {
                infoWindow.setContent(position.lat + ", " + position.lng);
                infoWindow.open(map, marker);
            });
            return marker
        });

        new MarkerClusterer({ markers, map });
    }


    locationData?.length && initMap();
  }, [locationData]);

  return (
    <div
      ref={googleMapRef}
      style={{ width: '100%', height: '400px', border: '1px solid #ccc' }}
    ></div>
  );
};

export default MapComponent;
