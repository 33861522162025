import React, { useState, useEffect, useRef } from "react";
import "./dashboard.css";
import Sidebar from "../../components/sidebar";
import PageHeader from "../../components/Header";
import { UserCount } from "../../sevices/account";
import { jobPostCount, UserLocationList } from "../../sevices/account";
import DashboardMap from "./dashboardMap";
import DashboardSalesData from "./dashboardSalesData";
import DashboardUsersData from "./dashboardUsersData";
import DashboardData from "./dashboardData";
import DashboardPostsData from "./dashboardPostsData";
import useUserRole from "../../components/UserRole.js";
import { Typography } from "@mui/material";

// CommonModal
const DashboardScreen = ({ currentMenu }) => {
  const { userRole, currentUser } = useUserRole();
  const [userCount, setUserCount] = useState("");
  const [postCount, setPostCount] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [locationDatainitial, setLocationDatainitial] = useState({});
  const [loading, setLoading] = useState(false);






  const userCountData = async () => {
    setLoading(true);
    try {
      const response = await UserCount({});
      if (response?.status === 200) {
        setUserCount(response?.data);
        getJobPostCount();
      }
    } catch (error) {
      setLoading(false);
      console.log("ERR58R", error);
    }
  };
  const getJobPostCount = async () => {
    try {
      const response = await jobPostCount({});
      if (response?.status === 200) {
        // setLoading(false)
        setPostCount(response?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    userCountData();
    getlocationDetails();
    // getlocationDetails();
  }, []);
  const getlocationDetails = async () => {
    try {
      const response = await UserLocationList({});
      if (response?.status === 200) {
        setLocationData(response?.data?.details?.items);
        if (response?.data?.details?.items?.length > 0) {
          setLocationDatainitial(response?.data?.details?.items[0]);
        } else {
        }
      }
    } catch (error) {
      // setLoading(false)
    }
  };
  const initialCenter = {
    lat: locationDatainitial?.latitude,
    lng: locationDatainitial?.longitude,
  };
  const [mapCenter, setMapCenter] = useState(initialCenter);

  return (
    <div >
      {/* <div className="d-flex flex-column flex-root header-fixed"> */}
      <div className="page d-flex flex-row flex-column-fluid">
        <Sidebar />
        <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
          <PageHeader />
          <div className="Page-inner-container">
            <Typography
              variant="h4"
              component="h2"
              style={{
                color: "#333",
                textTransform: "uppercase",
                marginBottom: 10,
              }}
            >
              Dashboard
            </Typography>
            {/* <div className="d-flex flex-column flex-root header-fixed"> */}
            {/* <div > */}
            <DashboardData />
            {/* <DashboardUsersData />
            <DashboardSalesData />
            <DashboardPostsData /> */}
            {/* </div> */}
            <DashboardMap />


          </div>



        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
