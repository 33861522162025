import * as React from 'react';

export function lazyImport(factory = f => f, name) {
  const LazyComponent = React.lazy(() => factory().then((module) => {
    if(module[name]) {
        return ({ default: module[name] })
    }
    return module
  }));
  return { [name]: LazyComponent };
}
