export const ROUTE_URL =[
    "/dashboard",
    "/employee-list",
    "/employer-list",
    "/employee-status",
    "/employee-view",
    "/employer-view",
    "/employee-create",
    "/job-post-list",
    "/mark-as-paid",
    "/bank-details",
    "job-post-list-all",
    "/sales-executive-list",
    '/sales-executive-create',
    "/employer-complaint/list",
    "/employee-complaint/list",
    "/employee-complaint/view",
    "/employer-complaint/view"





]

export const PUBLIC_URL =[
    "/",
]

export const ROLE ={
    ADMIN : 3
  }