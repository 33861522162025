import { Button, Menu } from 'antd';
import { AppstoreOutlined, MailOutlined, PaypalOutlined, MenuOutlined, UserOutlined, LogoutOutlined, DashboardOutlined } from '@ant-design/icons';
import logo from '../../assets/img/Logo White TM.png';
import "./sidebar.css"
import BusinessIcon from '@mui/icons-material/Business';
import { removeItemFrmLocalStorage } from '../../utils/genericUtilities';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SideMenuExecutive from './side-menu';

const { SubMenu } = Menu;
// let userRole =  ; // dynamic menus
const Sidebar = () => {
	const [userRole, setUserRole] = useState(null);
	useEffect(() => {
		const loginType = JSON.parse(localStorage.getItem('currentUser'));
		if (loginType && loginType.types && loginType.types.length > 0) {
		  setUserRole(loginType.types[0]);
		}
	  }, []);
	
	const loginType = JSON.parse(localStorage.getItem('currentUser'));
	const [openKeys, setOpenKeys] = useState([]);

	const location = useLocation();
	let currentLocation = useLocation().pathname;

	const navigate = useNavigate();
	const menuToggle = () => {
		document.body.classList.toggle('menu-close');
	}
	const handleLogout = () => {
		removeItemFrmLocalStorage(['token', 'currentUser'])
		navigate("/")
	}
	const employeeList = () => {
		handleMenuItemClick('1')
		navigate("/employee-list")
	}
	const employeeCreate = () => {
		handleMenuItemClick('1')
		navigate("/employee-create")
	}
	const jobList = () => {
		handleMenuItemClick('3')
		navigate("/job-post-list")
	}

	/* added menu 
	 	const salesExecutiveList = () => {
	 	handleMenuItemClick('3')
	 	navigate("/sales-executive-list") 
	 } */
	const targetSetting = () => {
		handleMenuItemClick('8')
		navigate("/target-update?id=1")
	}
	const jobCreate = () => {
		handleMenuItemClick('4')
		navigate("/job-post-create")
	}
	const jobListAll = () => {
		handleMenuItemClick('3')
		navigate("/job-post-list")
	}
	// start-sales manager section for admin
	const salesMangerCreate = () => {
		handleMenuItemClick('10')
		navigate("/manager-create")
	}
	const salesMangerList = () => {
		handleMenuItemClick('11')
		navigate("/manager-list")
	}
	// end-sales manager section for admin
	const [currentMenu, setCurrentMenu] = useState(null); //display current after clicked
	const handleMenuItemClick = (key) => {
		if (key === '2') {
			setOpenKeys('sub2');
		} else if (key === '1') {
			setOpenKeys('sub1');
		} else if (key === '3') {
			setOpenKeys('sub3');
		}
		setCurrentMenu(key); 
	};

	return (
		<div id="kt_aside" className="aside aside-dark aside-hoverable Sidebar-Menu-Container" style={{ height: '100vh' }}>
			<div className="aside-logo flex-column-auto" id="kt_aside_logo">
				<a>
					<img alt="Logo" src={logo} className="h-25px logo" />
				</a>
				<span className="SideMenuIcon" onClick={menuToggle}>
					<MenuOutlined />
				</span>
			</div>
			{/* {console.log("DEFAYSDA8454", openKeys)} */}
			<div className="aside-menu flex-column-fluid hover-scroll-overlay-y">
				<Menu
					style={{ width: 256 }}
					defaultSelectedKeys={['sub1']}
					// openKeys={openKeys}
					defaultOpenKeys={[openKeys]}
					mode="inline"
				>

					{/* FOR ADMIN */}
								<SideMenuExecutive userRole={userRole} handleMenuClick={handleMenuItemClick}/>
{/* Pass currentMenu to the Homepage component */}
					{
				// 		loginType.types[0] === 0 ?
				// 			<>
				// 				<Menu.Item icon={<DashboardOutlined />} onClick={() => navigate("/dashboard")} key="sub1">Dashboard</Menu.Item>
				// 				<SubMenu key="sub2" icon={<UserOutlined />} title="Part-Timers">
				// 					<Menu.Item onClick={employeeCreate} key="1">Create</Menu.Item>
				// 					<Menu.Item onClick={employeeList} key="2">List</Menu.Item>

				// 				</SubMenu>
				// 				<SubMenu key="sub3" icon={<BusinessIcon />} title="Employer">
				// 					<Menu.Item key="3"
				// 						onClick={() => {
				// 							handleMenuItemClick('3')
				// 							navigate("/employer-create")
				// 						}}
				// 					>Create</Menu.Item>
				// 					<Menu.Item key="4"
				// 						onClick={() => {
				// 							handleMenuItemClick('2')
				// 							navigate("/employer-list")
				// 						}}
				// 					>List</Menu.Item>

				// 				</SubMenu>
				// 				<SubMenu key="sub4" icon={<BusinessIcon />} title="Jobs">
				// 					<Menu.Item onClick={jobCreate} key="5">create</Menu.Item>
				// 					<Menu.Item key="6"
				// 						onClick={() => {
				// 							handleMenuItemClick('3')
				// 							navigate("/job-post-list-all")
				// 						}}>Job List</Menu.Item>
				// 				</SubMenu>
				// {/* sales manger menu */}
				// 				<SubMenu key="sub5" icon={<BusinessIcon />} title="Sales Manager">
				// 					<Menu.Item onClick={salesMangerCreate} key="7">Create</Menu.Item>
				// 					<Menu.Item onClick={salesMangerList} key="8">List</Menu.Item>
				// 				</SubMenu>
				// 				<SubMenu key="sub6" icon={<BusinessIcon />} title="Employer">
				// 					<Menu.Item key="9"
				// 						onClick={() => {
				// 							handleMenuItemClick('3')
				// 							navigate("/employer-create")
				// 						}}
				// 					>Create</Menu.Item>
				// 					<Menu.Item key="10"
				// 						onClick={() => {
				// 							handleMenuItemClick('2')
				// 							navigate("/employer-list")
				// 						}}
				// 					>List</Menu.Item>

				// 				</SubMenu>
				// 			</>
				// 			: null
					}

					{/* FOR SALES MANAGER */}

					{/* {
						loginType.types[0] === 3 ?
							<>
								<Menu.Item icon={<DashboardOutlined />} onClick={() => navigate("/dashboard")} key="sub7">Dashboard</Menu.Item>

								<SubMenu key="sub8" icon={<BusinessIcon />} title="Sales Executives">
									<Menu.Item onClick={salesExecutiveList} key="11">List</Menu.Item>
									<Menu.Item onClick={targetSetting} key="12">Target Setting</Menu.Item>
								</SubMenu>
							</>
							: null} */}

					{/* {
						loginType.types[0] === 4 ?
							<>
								<SideMenuExecutive userRole={userRole}/>
							</>
							: null} */}


					{
						// loginType.types[0] === 5 ?
						// <>
						// <Menu.Item icon={<DashboardOutlined />} onClick={() => navigate("/dashboard")} key="sub9">Dashboard</Menu.Item>
						// <SubMenu key="sub10" icon={<BusinessIcon />} title="Complaints">
						// 		<Menu.Item key="13"
						// 			onClick={() => {
						// 				handleMenuItemClick('5')
						// 				navigate("/employee-complaint-list")
						// 			}}>From Part-timers</Menu.Item>
						// 		<Menu.Item key="14"
						// 			onClick={() => {
						// 				handleMenuItemClick('6')
						// 				navigate("/employer-complaint-list")
						// 			}}>From Employers</Menu.Item>

						// 	</SubMenu>
						// </>
							
						// 	: null
							}

					{/* <Menu.Item icon={<LogoutOutlined />} onClick={handleLogout} key="sub11">Sign out</Menu.Item> */}

				</Menu>

			</div>
			{/* <Button onClick={handleLogout} >
				Sign out
			</Button> */}

		</div>)
}

export default Sidebar