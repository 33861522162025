import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ENV_CONFIG } from '../constant';
import { loginWithOuth } from '../sevices/account';
import { getCurrentUserData } from './genericUtilities';
// import dayjs from 'dayjs';

let authTokens = getCurrentUserData()

const axiosInstance = axios.create({
  baseURL: ENV_CONFIG.API_URL,
});

const WILD_CARD_APIS = [
  '/oauth/token',
]

axiosInstance.interceptors.request.use(
  (config) => {

    // Check if the request URL is for login or register
    if (WILD_CARD_APIS?.includes(config.url)) {
      // Skip the interceptors for wild card requests
      return config;
    } else {
      let authTokens = getCurrentUserData();
      if (authTokens?.access_token) {
        config.headers.Authorization = `Bearer ${authTokens?.access_token}`
      }
    }
    // Apply the interceptors for other requests
    // console.log("Request intercepted:", config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((response) => {
  return response;
},
  async (error) => {
    if (error?.response?.status === 401) {

      console.log('Unauthorized!');
    } else if (error?.response?.status === 403) {
      console.log('Forbidden!');
    }
    return Promise.reject(error);
  }
);
export default axiosInstance