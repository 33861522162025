
import React, { useState, useEffect } from 'react';

import Sidebar from '../../components/sidebar';
import PageHeader from '../../components/Header';
import PageFooter from '../../components/Footer';
import Loader from '../../constant/Loader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
// import './style.css';
import { Button, TextField, Grid, InputAdornment, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import DoneIcon from '@mui/icons-material/Done';
import { CompanyLogo, ExecutiveCreate, ExecutiveUpdate, GovtDoc, SalesExecutiveView, TargetUpdate, TargetView, sendOtpEmployer, verifyOtpEmployer } from '../../sevices/account';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { isEmptyObj, removeElement } from '../../utils/genericUtilities';
import { useValidation } from '../../constant';
import { notification } from 'antd';

const TargetCreate = () => {
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search);
    const viewID = queryParams.get('id');

    // const {
    //     target,
    //     setEmployerTarget,partTimerTarget,setPartTimerTarget,
    //     targetError,
    //     setEmployerTargetError,
    //     descriptionError,setDescriptionError,
    // } = useValidation();

    const [EmployerTarget, setEmployerTarget] = useState(0);
    const [partTimerTarget, setPartTimerTarget] = useState(0);

    const [loading, setLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [numberVerified, setNumberVerified] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpResponse, setOtpResponse] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [customMsg, setCustomMsg] = useState({});
    const [companyType, setCompanyType] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [aboutCompany, setAboutCompany] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [addSocialMediaLinks, setAddSocialMediaLinks] = useState([{ value: '' }]);
    const [errorPopupOpen, setErrorPopupOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [selectedGovtDoc, setSelectedGovtDoc] = useState(null);



    // const handleAddMore = () => {
    //     const values = [...addSocialMediaLinks];
    //     values.push({ value: '' });
    //     setAddSocialMediaLinks(values);
    // }

    // const handleDeleteMore = (index) => {
    //     if (index > -1) {
    //         const array = removeElement(addSocialMediaLinks, index);
    //         setAddSocialMediaLinks(array)
    //     }

    // }

 

    // const handleCompanyWebsite = (event) => {
    //     setCompanyWebsite(event.target.value);


    // }
    // const handleChange = (event) => {
    //     setCompanyType(event.target.value);

    // };

    // const handleCompanyAddress = (event) => {
    //     setaddress(event.target.value);
    // }
    // const handleChangestate = (event) => {
    //     setState(event.target.value);
    // }
    // const handleAboutCompany = (event) => {
    //     setAboutCompany(event.target.value);
    // }

    // const handleCity = (event) => {
    //     setCity(event.target.value);
    // }

    // const handleEmailChange = (event) => {
    //     const newEmail = event.target.value;
    //     setEmail(newEmail);
    //     //setIsValidEmail(validateEmail(newEmail));
    // };

    // const validateForm = () => {
    //     return (
    //         email &&
    //         password &&
    //         firstName &&
    //         phone &&
    //         address &&
    //         lastName &&
    //         !addressError &&
    //         !stateError &&
    //         !lastnameError &&
    //         !firstnameError &&
    //         !confirmPasswordError &&
    //         !passwordError &&
    //         !emailError
    //     );
    // };
    // const validateUpdateForm = () => {
    //     return (
    //         email &&
    //         // password &&
    //         firstName &&
    //         phone &&
    //         address &&
    //         lastName &&
    //         !addressError &&
    //         !stateError &&
    //         !lastnameError &&
    //         !firstnameError &&
    //         // !confirmPasswordError &&
    //         // !passwordError &&
    //         !emailError
    //     );
    // };

    useEffect(() => {
        if (viewID) {
            getViewTarget()

        }
    }, []);
    const getViewTarget = async () => {
        setLoading(true)
        const params = {}
        params.id = viewID
        try {

            // const response = await SalesExecutiveView(params);
            const response = await TargetView(params);

            if (response?.status === 200) {
                console.log('RESPONSEEE845', response?.data?.details);
                // setViewAdminData(response);
                setLoading(false)
                setEmployerTarget(response?.data?.details?.employer_target)
                setPartTimerTarget(response?.data?.details?.part_timer_target)
                // setEmail(response?.data?.details?.email)
                // setPhone(response?.data?.details?.phone)
                // setaddress(response?.data?.details?.address)
                // setState(response?.data?.details?.state)
                // setCity(response?.data?.details?.city)
                // setZipCode(response?.data?.details?.pincode)
                // form.setFieldsValue({
                //     first_name: response?.data?.details?.first_name,
                //     last_name: response?.data?.details?.last_name,
                //     email: response?.data?.details?.email,
                //     phone: response?.data?.details?.phone
                // })
            }
        } catch (error) {
            setLoading(false)

            console.log("Error:", error);
        }
    };

    // const onsubmit = async () => {

    //     // if (!validateForm()) {
    //     //     return;
    //     // }

    //     const body = {}
    //     body.email = email
    //     body.password = password
    //     body.company_name = companyName
    //     body.about_company = aboutCompany
    //     body.first_name = firstName
    //     body.last_name = lastName
    //     body.address = address
    //     body.state = state
    //     body.city = city
    //     body.pincode = zipCode

    //     body.phone = "+91" + phone

    //     console.log("BODDDYDYDDY", body);
    //     try {
    //         const response = await ExecutiveCreate(body)
    //         console.log("RESP[OIISN", response);
    //         if (response?.status == 200) {

    //             notification.success({
    //                 message: "Sales Executive Successfully created",
    //             })
    //             navigate("/sales-executive-list")
    //             // let employerId = response?.data?.details?.id;

    //             // if (selectedFile && !isEmptyObj(selectedFile)) {
    //             //     uploadLogo(employerId)
    //             // }

    //             // if (selectedGovtDoc && !isEmptyObj(selectedGovtDoc)) {
    //             //     uploadGovtDoc(employerId)
    //             // }



    //         }
    //     } catch (error) {
    //         console.log("EERDAFC", error);

    //     }
    // }

    const onUpdate = async () => {

        // if (!validateUpdateForm()) {
        //     return;
        // }

        const body = {}
        body.id = viewID
        body.employer_target = EmployerTarget   
        body.part_timer_target = partTimerTarget
   

        console.log("BODDDYDYDDY", body);
        try {
            const response = await TargetUpdate(body)
            // const response = await ExecutiveUpdate(body)

           
            console.log("RESP[OIISN", response);
            if (response?.status == 200) {

                notification.success({
                    message: "Target Successfully updated",
                })
                navigate("/target-update")
                // let employerId = response?.data?.details?.id;

                // if (selectedFile && !isEmptyObj(selectedFile)) {
                //     uploadLogo(employerId)
                // }

                // if (selectedGovtDoc && !isEmptyObj(selectedGovtDoc)) {
                //     uploadGovtDoc(employerId)
                // }



            }
        } catch (error) {
            console.log("EERDAFC", error);

        }
    }
    // const uploadGovtDoc = async (id) => {
    //     setLoading(true);
    //     const body = {};
    //     body.id = 86

    //     const formData = new FormData();
    //     formData.append('photo', selectedGovtDoc);
    //     console.log("data--->", formData);
    //     try {
    //         const response = await GovtDoc(formData, id);
    //         console.log('RESLCN85', response);
    //     } catch (err) {
    //         setLoading(false);

    //         console.log('error', err)
    //     }

    // }

    // const uploadLogo = async (id) => {
    //     setLoading(true);
    //     const body = {};
    //     body.id = 86

    //     const formData = new FormData();
    //     formData.append('photo', selectedFile);
    //     try {
    //         const response = await CompanyLogo(formData, id);
    //         console.log('RESLCN', response);
    //         if (response?.status == 200) {
    //             setLoading(false);
    //         } else {
    //         }
    //     } catch (err) {
    //         console.log('error', err)
    //         setLoading(false);
    //     }

    // }


    return (
        <div className="d-flex flex-column flex-root header-fixed">
            <div className="page d-flex flex-row flex-column-fluid">
                <Sidebar />
                <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
                    <PageHeader />
                    <div className="Page-inner-container">
                        {/* <span>Employer Create</span> */}
                        <div className='row'>
                            <p className='headName'>Target Update</p>
                        </div>
                        <form>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="target"
                                        label="Employer Target"
                                        className="form-control"
                                        value={EmployerTarget}
                                        defaultValue={EmployerTarget}

                                        // error={!!targetError}
                                        type={'text'}
                                        // helperText={targetError}
                                        // onBlur={handlefirstnameBlur}
                                        onChange={(event) => setEmployerTarget(event.target.value.replace(/[^0-9]/g, ''))}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                        inputProps={{
                                            maxLength: 8
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="partTimerTarget"
                                        label="Part Timer Target "
                                        className="form-control"
                                        value={partTimerTarget}
                                        // error={!!descriptionError}
                                        // helperText={descriptionError}
                                        // onBlur={handleLastnameBlur}
                                        defaultValue={partTimerTarget}
                                        onChange={(event) => setPartTimerTarget(event.target.value.replace(/[^0-9]/g, ''))}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                        inputProps={{
                                            maxLength: 8
                                        }}
                                    />
                                </Grid>
                        
                              




                                <Grid item xs={12} sm={12}>
                                    <div className="WizardNextStep">
                                        
                                        <Button style={{ marginRight: "20px", backgroundColor: 'green' }} onClick={onUpdate} variant="contained" className="WizardNextBtn" >
                                            <span color='green' >Update</span>
                                        </Button> 
                                       

                                    </div>
                                </Grid>
                            </Grid>
                        </form>

                    </div>
                    <PageFooter />
                </div>
            </div>
            <Loader loading={loading} />
        </div>

    );

}
export default TargetCreate;