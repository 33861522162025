import dayjs from "dayjs";

export const setCurrentUser = (response) => {
  // console.log("USERITEM",response)
    if(localStorage && !isEmptyObj(response)) {
        localStorage.setItem("currentUser", JSON.stringify(response));
        localStorage.setItem("token", response?.access_token);
    }
  };

  export const containsText = (text, searchText) => {
    if (text && searchText) {
      return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    }
    return false;
  }
  
  
  export const removeItemFrmLocalStorage = (keysToRemove = []) => {
    if (keysToRemove.length) {
      for (let i of keysToRemove) {
        localStorage.removeItem(i);
      }
    }
  }
  
  export const checkBetween = (x = 0, minValue = 0, maxValue = 0) => {
    return x >= minValue && x <= maxValue;
  }
  
  /**
      **	Check Object
    **/
  export const isEmptyObj = (obj) => {
    if(obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype) {
      return true;
    }
    return false;
  }
  
  // get user date
  export const getCurrentUserData = () => {
    return typeof window !== 'undefined' ? getDataFromLocalStorage('currentUser') : null;
  }
  /**
   * Retrieves data from localStorage for the given key.
   * @param key - The key to retrieve data from localStorage.
   * @returns The data stored in localStorage for the given key, or `null` if
   *   no data was found or an error occurred while parsing the data.
   */
  export const getDataFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);
  
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (error) {
        console.log(`Error parsing localStorage data for key '${key}': ${error.message}`);
      }
    }
  
    return null;
  }
  
  
  
  /**
   * Removes empty array elements from the given array.
   * @param arr - The array to filter out empty elements from.
   * @returns The filtered array.
   */
  export const removeEmptyArrayElements = (arr) => { 
    if (!Array.isArray(arr)) {
       return arr;
    } else {
        return arr.filter( function(elem) { 
           return (elem.slotDate !== null && elem.startTime !== null)
        } ).map(removeEmptyArrayElements)
    }
  }
  
  
  
  /**
   * Removes an element from an array at a given index.
   * @param myArray The array from which to remove an element.
   * @param indexToRemove The index of the element to remove.
   * @returns A new array with the element removed.
   */
  export const removeElement = (myArray = [], indexToRemove) => {
    if(myArray?.length && indexToRemove > -1) {
      const newArray = [...myArray.slice(0, indexToRemove), ...myArray.slice(indexToRemove + 1)];
      return newArray;
    }
    return myArray?.length ? myArray: [];
    
  };

  export const isExpiredRefreshToken = () => {
    const expiresInSeconds = 300; // 5 minutes
    const expiresInMillis = expiresInSeconds * 1000;
    const expiresIn = getCurrentUserData()?.expires_in;
    // console.log("BCDCDKN",expiresIn);
    const date = new Date();
    const currentTime = date.getTime();
    return { status: (currentTime >= (expiresIn - expiresInMillis)), expiresIn};
  }

  export const convertTimestampToHumanDate = (timeStampValue, format = 'DD/MM/YYYY') => {
    if(timeStampValue) {
      const date = new Date(timeStampValue * 1000);
      return dayjs(date).format(format);
    }
  }