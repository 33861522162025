import React, { useState, useEffect } from "react";
import "./dashboard.css";
import {  UserLocationList } from "../../sevices/account";
import MapComponent from "../../components/Map/map";

// CommonModal
const DashboardMap = () => {
  const [locationData, setLocationData] = useState([]);
  const [locationDatainitial, setLocationDatainitial] = useState({});
  
  useEffect(() => {
    getlocationDetails();
    // getlocationDetails();
  }, []);
  const getlocationDetails = async () => {
    try {
      const response = await UserLocationList({});
      if (response?.status === 200) {
        setLocationData(response?.data?.details?.items);
        if (response?.data?.details?.items?.length > 0) {
          setLocationDatainitial(response?.data?.details?.items[0]);
        } else {
        }
      }
    } catch (error) {
      console.log()
      // setLoading(false)
    }
  };
  const initialCenter = {
    lat: locationDatainitial?.latitude,
    lng: locationDatainitial?.longitude,
  };
  const [mapCenter, setMapCenter] = useState(initialCenter);

  return (
    // <div className="flex-root header-fixed">
      <div>

          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <div className="col-sm-9 mt-4 text-center">
              <div className="p-3 cardShadow">
                <MapComponent locationData={locationData} />
              </div>
            </div>
          </div>
      </div>
    // </div>
  );
};

export default DashboardMap;
