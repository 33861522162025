import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ContactProfileTargetApi } from '../../sevices/account';


const useStyles = makeStyles({
    root: {
        minWidth: 250,
        maxWidth: 370,
        minHeight: 160,
        maxHeight: 280,
        margin: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center', // Center text horizontally
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    numericValue: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    smallText: {
        fontSize: 14,
    },
    // cardContainer: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     gap: '20px',
    //     justifyContent: 'center',
    // },
});


function SalesTargetCard({ target, numericValue1, smallText1, numericValue2, smallText2, backgroundColor }) {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card variant="outlined" className={classes.root} style={{ backgroundColor }}>
                <CardContent>
                    <Typography className={classes.title} gutterBottom>
                        {target}
                    </Typography>
                    <Typography variant="h5" component="p" className={classes.numericValue}>
                        {numericValue1}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.smallText}>
                        {smallText1}
                    </Typography>
                    <Typography variant="h5" component="p" className={classes.numericValue}>
                        {numericValue2}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.smallText}>
                        {smallText2}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}

function DashboardSalesData() {
    const classes = useStyles();

    // async function fetchData() {
    //     try {
    //         const targetAchieved = await ContactProfileListApi();
    //         // Use the fetched data here
    //         console.log("targetAchieved",targetAchieved.data.target_achieved);
    //     } catch (error) {
    //         // Handle any errors that occur during the API call
    //         console.error("Error fetching data:", error);
    //     }
    // }
    
    // // Call the function to fetch the data
    // fetchData();
    const [targetAchievedData, setTargetAchievedData] = useState(null);
    const [managerTarget, setManagerTarget] = useState(null);
    const [personTarget, setPersonTarget] = useState(null);

    async function fetchData() {
        try {
            const targetAchieved = await ContactProfileTargetApi();
            // Update the state with the fetched data
            setTargetAchievedData(targetAchieved.data.target_achieved);
            setManagerTarget(targetAchieved.data.manager_target);
            setPersonTarget(targetAchieved.data.set_target);

        } catch (error) {
            // Handle any errors that occur during the API call
            console.error("Error fetching data:", error);
        }
    }

    // useEffect hook to fetch data when the component mounts
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts

    // For committing changes

    const rate = 4130 ;

    return (
        <Box>
            <Grid container spacing={2} className={classes.cardContainer}>
                <SalesTargetCard 
                    target={<span style={{ fontSize: '20px' }}>Sales Target</span>} 
                    numericValue1={personTarget} 
                    smallText1="Units" 
                    numericValue2={personTarget * rate} 
                    smallText2="Revenue" 
                    backgroundColor="rgb(241, 244, 255)" 
                />
                <SalesTargetCard 
                    target={<span style={{ fontSize: '20px' }}>Target Achieved</span>} 
                    numericValue1={targetAchievedData} 
                    smallText1="Units" 
                    numericValue2={rate * targetAchievedData} 
                    smallText2="Revenue" 
                    backgroundColor="rgb(252, 222, 234)" 
                />
                <SalesTargetCard 
                    target={<span style={{ fontSize: '20px' }}>Over all Sales</span>} 
                    numericValue1={targetAchievedData} 
                    smallText1="Units" 
                    numericValue2={rate * targetAchievedData} 
                    smallText2="Revenue" 
                    backgroundColor="rgb(253, 243, 229)" 
                />
            </Grid>
        </Box>
    );
}

export default DashboardSalesData;
