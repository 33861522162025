import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';


  const Loader = ({ loading }) => {
    return (
        <Backdrop open={loading} style={{ zIndex: 9999,color: '#223385'}}>
          <CircularProgress color="inherit" />
        </Backdrop>
    );
  };
  
  export default Loader;