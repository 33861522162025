
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/bootstrap.css';
// Plugin
import { CircularProgress, Grid } from '@mui/material';
import './App.css';
import { ENV_CONFIG } from './constant';
import { loginWithOuth } from './sevices/account';
import { getCurrentUserData, isEmptyObj, isExpiredRefreshToken, setCurrentUser } from './utils/genericUtilities';
import RouteComponent from './routes';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// App Global Style
// import './App.scss';
import ScrollToTop from './routes/scrollToTop';
function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const refreshAccessToken = async () => {
    // Make a request to refresh token endpoint
    const userData = getCurrentUserData();
    let requestParams = {};
    requestParams.refresh_token = userData?.refresh_token;
    requestParams.client_id = ENV_CONFIG.CLIENT_ID;
    requestParams.client_secret = ENV_CONFIG.CLIENT_SECRET;
    requestParams.grant_type = "refresh_token";
    try {
      const response = await loginWithOuth(requestParams);
      if (response?.status == 200) {

        let date = new Date();
        let currentTime = date.getTime();
        const expires_in = currentTime + response?.data?.expires_in * 1000;
        setCurrentUser({ ...response?.data, expires_in });
      }
    } catch (err) {
      console.log('error', err)
    }
  };

  useEffect(() => {
    const tokenExpiration = isExpiredRefreshToken();
    const targetTimestamp = tokenExpiration?.expiresIn;
    const currentTime = Date.now();
    const timeDifference = targetTimestamp - 300000; //checking for fixing refresh



    if (currentTime > targetTimestamp) {
      tokenExpiration?.status && refreshAccessToken();
    }
    else {

      const refreshTimer = setTimeout(() => {
        tokenExpiration?.status && refreshAccessToken();
      }, timeDifference); // Refresh 5 minutes before expiration


    }

    // Checking User Authenticated
    const findUser = getCurrentUserData();
    console.log("USERfIND---->", findUser);
    if (!isEmptyObj(findUser) && findUser?.access_token) {
      setAuthenticated(true);
    }


  }, []);

  return (
    <BrowserRouter>
    <ScrollToTop>
    <Suspense
        fallback={
          <Grid item md={12} height={"90vh"} display={'flex'} justifyContent="center" alignItems={"center"}>
            <CircularProgress />
          </Grid>
        }
      >
        <RouteComponent  />
      </Suspense>
    </ScrollToTop>
     
    </BrowserRouter>
  );
}

export default App;