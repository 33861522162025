
import axiosInstance from '../utils/axiosConfig'
import { useNavigate } from "react-router-dom";
import { ACCOUNT_API_URL } from '../constant/apiUrls';

const headers = {
  'Content-Type': 'application/json'
}

// Send credentials to the API
export const loginWithOuth = (queryParams) => {
  console.log("QURRYYY", queryParams);
  return axiosInstance.post(ACCOUNT_API_URL.login, queryParams)
}
//employee list
export const employeeList = (body) => {
  // console.log("dddddddgggg",details);
  return axiosInstance.post(ACCOUNT_API_URL.employeelist,body)
}
export const EmployerListapi = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.employerList,body)
}
export const SalesExecutiveList = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.salesExecutiveList,body)
}
export const SalesExecutiveView = (params) => {
  return axiosInstance.get(ACCOUNT_API_URL.salesExecutiveView, { params })
}
export const TargetView = (params) => {
  return axiosInstance.get(ACCOUNT_API_URL.TargetView, { params })
}
export const ExecutiveCreate = (body) => {
  // console.log("QURRYYYsss", body);
  return axiosInstance.post(ACCOUNT_API_URL.ExecutiveCreate,body)
}
export const ExecutiveUpdate = (body) => {
  // console.log("QURRYYYsss", body);
  return axiosInstance.put(ACCOUNT_API_URL.ExecutiveUpdate,body)
}

export const TargetUpdate = (body) => {
  // console.log("QURRYYYsss", body);
  return axiosInstance.put(ACCOUNT_API_URL.TargetUpdate,body)
}



export const UserCount = () => {
  return axiosInstance.get(ACCOUNT_API_URL.userCount)
}
export const jobPostCount = () => {
  return axiosInstance.post(ACCOUNT_API_URL.jobPostCount)
}
export const UserLocationList = () => {
  return axiosInstance.get(ACCOUNT_API_URL.userLocation)
}

export const EmployerViewapi = (params) => {
  return axiosInstance.get(ACCOUNT_API_URL.employerView, { params })
}
export const EmployerStatus = (body) => {
  // console.log("QURRYYYsss", body);
  return axiosInstance.post(ACCOUNT_API_URL.employerStatusUpdate,body)
}
export const SalesExecutiveStatus = (body) => {
  // console.log("QURRYYYsss", body);
  return axiosInstance.post(ACCOUNT_API_URL.SalesExecutiveStatusUpdate,body)
}
export const employeeStatus = (queryParams) => {
  return axiosInstance.post(ACCOUNT_API_URL.employeeStatus,queryParams)
}
export const employeeView = (params) => {
  return axiosInstance.get(ACCOUNT_API_URL.employeeView,{params})
}
export const EmployeeCreate = (body) => {
  // console.log("QURRYffwdwdYYsss", body);
  return axiosInstance.post(ACCOUNT_API_URL.employeeCreate,body)
}
export const EmployeeProfile = (body, id) => {
  return axiosInstance.post(`${ACCOUNT_API_URL.employeeProfile}/${id}`, body)
}
export const JobPostList = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.jobPostList, body)
}
export const MarkAsPaid = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.markAsPaid, body)
}
export const BankDetails = (params) => {
  return axiosInstance.get(ACCOUNT_API_URL.bankDetails, {params})
}

export const PostJobCreate = (body) => {
  console.log('PostJobQuaryParams', body);
  return axiosInstance
    .post(ACCOUNT_API_URL.postJob, body)
}
export const JobCategoryTypes = (params) => {
  return axiosInstance
    .post(ACCOUNT_API_URL.JobCategoryList,null, {params}) 
}
export const JobCertification = (params) => {
  return axiosInstance
    .post(ACCOUNT_API_URL.JobCertificationList,null, {params}) 
}
export const JobQualification = (params) => {
  return axiosInstance
    .post(ACCOUNT_API_URL.JobQualificationsList,null, {params}) 
}

export const JobSkillSet = (params) => {

  return axiosInstance
    .post(ACCOUNT_API_URL.JobSkillList,null, {params}) 
}

export const createNewSkill = (params) => {
  return axiosInstance
  .post(ACCOUNT_API_URL.CreateNewSkills,params) 
}
export const EmployerCreate = (body) => {
  console.log("QURRYYYsss", body);
  return axiosInstance.post(ACCOUNT_API_URL.employerCreate,body)
}
export const sendOtpEmployer = (queryParams) => {
  return axiosInstance.post(ACCOUNT_API_URL.sendOtpEmployer, queryParams)
}

export const verifyOtpEmployer = (queryParams) => {
  return axiosInstance.post(ACCOUNT_API_URL.verifyOtpEmployer, queryParams)
}
export const GovtDoc = (body,id) => {
  console.log("QURRYYYsss", body,id);
  return axiosInstance.post(`${ACCOUNT_API_URL.govtDoc}/${id}`, body)
}
export const CompanyLogo = (body,id) => {
  console.log("QURRYYYsss", body,id);
  return axiosInstance.post(`${ACCOUNT_API_URL.companyLogo}/${id}`, body)
}
export const JobPostListAll = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.jobPostListAll,body)
}
export const StatusChange = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.statusChange,body)
}

export const employeeComplaintList = (body) => {
  // console.log("dddddddgggg",details);
  return axiosInstance.post(ACCOUNT_API_URL.employeeComplaintList,body)
}
export const employerComplaintList = (body) => {
  // console.log("dddddddgggg",details);
  return axiosInstance.post(ACCOUNT_API_URL.employerComplaintList,body)
}
export const employeeComplaintJobView = (params) => {
  console.log("gknhkjnh",params);
  return axiosInstance
    .post(ACCOUNT_API_URL.employeeComplaintJobView, params) 
}
export const employerComplaintJobView = (params) => {
  console.log("gknmikjmjmmkjhkjnh",params);
  return axiosInstance
    .post(ACCOUNT_API_URL.employerComplaintJobView, params) 
}
export const employerComplaintStatus = (body) => {
  // console.log("dddddddgggg",details);
  return axiosInstance.post(ACCOUNT_API_URL.employerComplaintStatus,body)
}
export const employeeComplaintStatus = (body) => {
  // console.log("dddddddgggg",details);
  return axiosInstance.post(ACCOUNT_API_URL.employeeComplaintStatus,body)
}


export const SalesExecutiveListApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.salesExecutiveListApi,body)
}
export const SalesExecutiveViewApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.salesExecutiveViewApi,body)
}
export const SalesExecutiveUpdateApi = (body) => {
  return axiosInstance.put(ACCOUNT_API_URL.salesExecutiveUpdateApi,body)
}

export const ManagerCreateApi = (body) => {
  console.log("QURRYYYsss", body);
  return axiosInstance.post(ACCOUNT_API_URL.managerCreateApi,body)
}
export const EmployeeListApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.employeeListApi,body)
}
export const ManagerListApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.managerListApi,body)
}
export const ManagerViewApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.managerViewApi,body)
}
export const ManagerUpdateApi = (body) => {
  return axiosInstance.put(ACCOUNT_API_URL.managerUpdateApi,body)
}


export const ContactProfileCreateApi = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.contactProfileCreateApi,body)
}
export const ContactProfileListApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.contactProfileListApi,body)
}
export const ContactProfileTargetApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.contactProfileTargetApi,body)
}



export const ContactProfileViewtApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.contactProfileViewApi,body)
}

export const ContactProfileUpdatetApi = (body) => {
  return axiosInstance.put(ACCOUNT_API_URL.contactProfileUpdateApi,body)
}


export const FunnelSheetListApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.funnelSheetListApi,body)
}

export const FunnelSheetViewApi = (body) => {
  return axiosInstance.get(ACCOUNT_API_URL.funnelSheetViewApi,body)
}

export const FunnelSheetUpdateApi = (body) => {
  return axiosInstance.put(ACCOUNT_API_URL.funnelSheetUpdateApi,body)
}


